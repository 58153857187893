<template>
	<div>
		<v-row dense>
			<v-col cols="12" xl="4" lg="4" md="4" sm="12">
				<el-select
					v-model="search.customer_id"
					filterable
					remote
					popper-class="el-select-customers"
					dusk="customer_id"
					placeholder="Cliente"
					:remote-method="searchCustomers"
					:loading="loading_search"
					loading-text="Cargando..."
					clearable
					@change="getRecordsByFilter"
				>
					<el-option
						v-for="option in customers"
						:key="option.id"
						:value="option.id"
						:label="option.name"
					></el-option>
				</el-select>
			</v-col>
			<v-col cols="12" xl="2" lg="2" md="2" sm="4">
				<el-input placeholder="Número" @input="getRecordsByFilter" v-model="search.number">
				</el-input>
			</v-col>
			<v-col cols="12" xl="3" lg="3" md="3" sm="4">
				<el-date-picker
					v-model="search.d_start"
					type="date"
					style="width: 100%;"
					placeholder="Desde"
					value-format="yyyy-MM-dd"
					format="dd/MM/yyyy"
					@change="changeDisabledDates"
				>
				</el-date-picker>
			</v-col>
			<v-col cols="12" xl="3" lg="3" md="3" sm="4">
				<el-date-picker
					v-model="search.d_end"
					type="date"
					style="width: 100%;"
					placeholder="Hasta"
					value-format="yyyy-MM-dd"
					format="dd/MM/yyyy"
					:picker-options="pickerOptionsDates"
					@change="getRecordsByFilter"
				>
				</el-date-picker>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" xl="12">
				<div class="table-responsive">
					<v-simple-table dark fixed-header dense>
						<template v-slot:default>
							<thead>
								<slot name="heading"></slot>
							</thead>
							<tbody>
								<slot
									v-for="(row, index) in records"
									:row="row"
									:index="customIndex(index)"
								></slot>
							</tbody>
						</template>
					</v-simple-table>
					<div class="mt-3">
						<v-pagination
							@next="getRecords"
							@previous="getRecords"
							@input="getRecords"
							:length="pagination.last_page"
							:total-visible="7"
							v-model="pagination.current_page"
							prev-icon="mdi-chevron-left"
							next-icon="mdi-chevron-right"
						></v-pagination>
					</div>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import queryString from 'query-string';
import dayjs from 'dayjs';

export default {
	props: {
		resource: String,
	},
	data() {
		return {
			loading_submit: false,
			loading_search: false,
			columns: [],
			records: [],
			customers: [],
			pagination: {},
			search: {},
			activePanel: 0,
			see_more: false,
			state_types: [],
			pickerOptionsDates: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return this.search.d_start > time;
				},
			},
			pickerOptionsDateOfIssue: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return dayjs().format('YYYY-MM-DD') < time;
				},
			},
		};
	},
	async created() {
		this.initForm();
		this.$eventHub.$on('reloadData', (resource) => {
			if (this.resource == resource) {
				this.getRecords();
			}
		});

		await this.getRecords();
	},
	// async mounted () {
	//     await this.getRecords()
	// },
	methods: {
		initForm() {
			this.search = {
				date_of_issue: null,
				document_type_id: null,
				state_type_id: null,
				customer_id: null,
				series: null,
				number: null,
				d_start: null,
				d_end: null,
			};
		},
		customIndex(index) {
			return this.pagination.per_page * (this.pagination.current_page - 1) + index + 1;
		},
		async getRecordsByFilter() {
			this.loading_submit = await true;
			await this.getRecords();
			this.loading_submit = await false;
		},
		searchCustomers(input) {
			if (input.length > 2) {
				this.loading_search = true;
				let parameters = `input=${input}`;
				this.$http
					.get(`/${this.resource}/search/customers-only?${parameters}`)
					.then((response) => {
						this.customers = response.data;
						this.loading_search = false;
					});
			} else {
				this.customers = [];
			}
		},
		getRecords() {
			return this.$http
				.get(`/${this.resource}/records?${this.getQueryParameters()}`)
				.then((response) => {
					this.records = response.data.data;
					this.pagination = response.data.meta;
					this.pagination.per_page = parseInt(response.data.meta.per_page);
					this.loading_submit = false;
				});
		},
		getQueryParameters() {
			return queryString.stringify({
				page: this.pagination.current_page,
				limit: this.limit,
				...this.search,
			});
		},
		changeClearInput() {
			this.search.value = '';
			// this.getRecords()
		},
		changeDisabledDates() {
			this.search.date_of_issue = null;
			if (this.search.d_end < this.search.d_start) {
				this.search.d_end = this.search.d_start;
			}
		},
	},
};
</script>
